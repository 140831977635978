const phoneCountries = [
  ['Albania (Shqipëri)', 'al', '355'],
  ['Andorra', 'ad', '376'],
  ['Austria (Österreich)', 'at', '43'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Belgium (België)', 'be', '32'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
  ['Bulgaria (България)', 'bg', '359'],
  ['Croatia (Hrvatska)', 'hr', '385'],
  ['Cyprus (Κύπρος)', 'cy', '357'],
  ['Czech Republic (Česká republika)', 'cz', '420'],
  ['Denmark (Danmark)', 'dk', '45'],
  ['Estonia (Eesti)', 'ee', '372'],
  ['Faroe Islands (Føroyar)', 'fo', '298'],
  ['Finland (Suomi)', 'fi', '358'],
  ['France', 'fr', '33'],
  ['Germany (Deutschland)', 'de', '49'],
  ['Gibraltar', 'gi', '350'],
  ['Greece (Ελλάδα)', 'gr', '30'],
  ['Hungary (Magyarország)', 'hu', '36'],
  ['Iceland (Ísland)', 'is', '354'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44'],
  ['Italy (Italia)', 'it', '39'],
  ['Jersey', 'je', '44'],
  ['Kosovo', 'xk', '383'],
  ['Latvia (Latvija)', 'lv', '371'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania (Lietuva)', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
  ['Malta', 'mt', '356'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Montenegro (Crna Gora)', 'me', '382'],
  ['Netherlands (Nederland)', 'nl', '31'],
  ['Norway (Norge)', 'no', '47'],
  ['Poland (Polska)', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Romania (România)', 'ro', '40'],
  ['San Marino', 'sm', '378'],
  ['Serbia (Србија)', 'rs', '381'],
  ['Slovakia (Slovensko)', 'sk', '421'],
  ['Slovenia (Slovenija)', 'si', '386'],
  ['Spain (España)', 'es', '34'],
  ['Sweden (Sverige)', 'se', '46'],
  ['Switzerland (Schweiz)', 'ch', '41'],
  ['Turkey (Türkiye)', 'tr', '90'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['United Kingdom', 'gb', '44'],
  ['Vatican City (Città del Vaticano)', 'va', '39'],
  ['Åland Islands', 'ax', '358']
]

export const formattedPhoneCountries = phoneCountries.map(([name, iso2, dialCode]) => ({
  name,
  iso2: iso2.toUpperCase(),
  dialCode
}))
