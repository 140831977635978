<template>
  <FormField
    :id="input?.id"
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError"
    :errorFixed="errorFixed"
    :errorMessage="errorMessage"
  >
    <textarea
      ref="input"
      v-uid
      v-bind="$attrs"
      :value="modelValue"
      :disabled="isDisabled"
      :maxLength="maxLength"
      :class="{
        isError,
        isDisabled
      }"
      @input="updateValue"
    />
    <template #bottomEnd>
      <div v-if="maxLength" class="self-end text-neutral-700">
        {{ modelValue.length }} / {{ maxLength }} {{ $t('forms.characters') }}
      </div>
    </template>
  </FormField>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
interface InputProps {
  classField?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: string
  isDisabled?: boolean
  isError?: boolean
  errorFixed?: boolean
  errorMessage?: string
  maxLength?: number
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: '',
  isDisabled: false,
  isError: false,
  errorFixed: true,
  maxLength: 2000
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}

const input = ref<null | HTMLElement>(null)
</script>
