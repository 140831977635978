<template>
  <FormField
    :id="input?.id"
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError"
    :error-message="errorMessage"
  >
    <vue-tel-input
      ref="input"
      v-model="phone"
      v-bind="$attrs"
      :disabled="isDisabled"
      :class="{
        inputPurple: style === 'purple',
        isError,
        isDisabled
      }"
      valid-characters-only
      :input-options="{
        placeholder: placeholder
      }"
      :allCountries="formattedPhoneCountries"
      :preferred-countries="['CH', 'DE', 'FR']"
      :auto-format="false"
      @onInput="updateValue"
      @countryChanged="onCountryChanged"
      @validate="onValidate"
      @open="isOpen = true"
      @close="isOpen = false"
    >
      <template #arrow-icon>
        <span v-if="dialCode" class="ml-2px text-[16px] leading-[150%]">&plus;{{ dialCode }}</span>
        <Icon
          name="chevron-down-general"
          class="ml-4px h-4px w-4px shrink-0 transition"
          :class="{
            'rotate-180': isOpen
          }"
        />
      </template>
    </vue-tel-input>
  </FormField>
</template>

<script lang="ts">
import { VueTelInput } from 'vue-tel-input'
import FormField from './FormField.vue'
import 'vue-tel-input/vue-tel-input.css'
import { formattedPhoneCountries } from '~/data/phoneCountries'

export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
export type InputStyles = 'gray' | 'purple'

interface InputProps {
  classField?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: string
  isDisabled?: boolean
  isError?: boolean
  errorMessage?: string
  style?: InputStyles
  placeholder?: string
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: '',
  isDisabled: false,
  isError: false,
  style: 'gray'
})

const input = ref<null | HTMLElement>(null)
const phone = ref('')
const dialCode = ref<string>()
const isOpen = ref(false)

const emit = defineEmits(['update:modelValue', 'validate', 'countryChanged'])

const updateValue = (event: any) => {
  phone.value = event.replace(/\D/g, '')
  emit('update:modelValue', `+${dialCode.value}${phone.value}`)
}

const onValidate = (event: any) => {
  emit('validate', event.valid)
}

const onCountryChanged = (event: any) => {
  dialCode.value = event.dialCode
  emit('update:modelValue', `+${dialCode.value}${phone.value}`)
  emit('countryChanged', event)
}
</script>

<style lang="postcss">
.vue-tel-input {
  @apply border-none;

  &:active,
  &:focus-within,
  &:focus {
    @apply shadow-none outline-none;
  }

  .vti__dropdown {
    @apply mr-2px rounded-[10px] border-[2px] border-transparent bg-neutral-50 px-4px py-2px text-neutral-600;

    &:focus-within {
      @apply border-black outline-none;
    }
  }

  .vti__input {
    @apply rounded-[10px] !border-2 !border-solid border-neutral-50 px-4px py-3px;
    color: var(--input-text-color);

    &:focus-within {
      border-color: black;
    }
  }

  .vti__dropdown-list {
    @apply max-w-xs rounded-xl border-none bg-white py-4px shadow-lg sm:max-w-none;

    &.below {
      top: 48px;
    }
  }

  .vti__selection .vti__flag {
    transform: scale(1.3);
  }

  .vti__flag {
    border-radius: 2px;

    &.ch {
      @apply w-5px bg-contain bg-center bg-repeat;
      background-image: url('/assets/images/ui/ch-flag.svg');
    }
  }

  .vti__dropdown-item {
    @apply relative mx-4 flex items-center gap-2px rounded-md px-4px py-2.5px text-[16px];

    &.last-preferred {
      @apply mb-2px border-b-0 after:absolute after:-bottom-1px after:left-0 after:right-0 after:h-px after:bg-neutral-300 after:content-[''];
    }

    .vti__flag {
      @apply ml-0 rounded-[2px];
    }

    strong {
      @apply font-normal;
    }

    span:last-of-type {
      @apply inline-block;
    }
  }

  .vti__dropdown-item {
    &[aria-selected='true'] {
      @apply relative bg-neutral-50;

      &::after {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.636 5.3637C20.9875 5.71517 20.9875 6.28502 20.636 6.63649L9.63601 17.6365C9.28453 17.988 8.71469 17.988 8.36321 17.6365L3.36321 12.6365C3.01174 12.285 3.01174 11.7152 3.36321 11.3637C3.71469 11.0122 4.28453 11.0122 4.63601 11.3637L8.99961 15.7273L19.3632 5.3637C19.7147 5.01223 20.2845 5.01223 20.636 5.3637Z' fill='green' /%3E%3C/svg%3E");
      }
    }
  }
}

.isError {
  .vti__dropdown,
  .vti__input {
    @apply !border-red;
  }
}
</style>
