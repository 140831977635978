<template>
  <FormField
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError"
    :errorMessage="errorMessage"
  >
    <div class="relative flex flex-col gap-x-3 gap-y-2 pb-7 md:flex-row md:flex-wrap">
      <div class="flex basis-[calc(50%-0.5rem)] gap-x-3">
        <template v-for="index in 10" :key="index">
          <button
            v-if="index <= 5"
            type="button"
            class="input text-18px min-h-10px min-w-10px flex items-center justify-center px-0 py-2 leading-normal"
            v-bind="$attrs"
            :disabled="isDisabled"
            :class="{
              inputPurple: style === 'purple',
              isDisabled,
              'border-2 border-black': activeIndex === index
            }"
            @click="updateValue(index)"
          >
            {{ index }}
          </button>
        </template>
      </div>
      <div class="flex basis-[calc(50%-0.5rem)] gap-x-3">
        <template v-for="index in 10" :key="index">
          <button
            v-if="index > 5"
            type="button"
            class="input text-18px min-h-10px min-w-10px flex items-center justify-center px-0 py-2 leading-normal"
            v-bind="$attrs"
            :disabled="isDisabled"
            :class="{
              inputPurple: style === 'purple',
              isDisabled,
              'border-2 border-black': activeIndex === index
            }"
            @click="updateValue(index)"
          >
            {{ index }}
          </button>
        </template>
      </div>
      <div
        class="absolute bottom-0 left-0 flex w-full basis-full items-center justify-between text-[14px] text-neutral-700"
      >
        <span>{{ leftLabel }}</span>
        <span>{{ rightLabel }}</span>
      </div>
    </div>
  </FormField>
</template>

<script lang="ts">
import FormField from './FormField.vue'

export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
export type InputStyles = 'gray' | 'purple'

interface InputProps {
  classField?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: number | undefined
  isDisabled?: boolean
  isError?: boolean
  errorMessage?: string
  style?: InputStyles
  leftLabel: string
  rightLabel: string
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: undefined,
  type: 'text',
  isDisabled: false,
  isError: false,
  style: 'gray'
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (index: number) => {
  activeIndex.value = index
  emit('update:modelValue', index)
}

const activeIndex = ref<number | undefined>()
</script>
